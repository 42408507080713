export const convertShortcodeBlockQuote = (text: string): string => {
  const start = '[ADD_BQ::';
  const divider = '::';
  const end = '::]';

  let indexEnd = -1;
  const indexStart = text.indexOf(start);
  if (indexStart !== -1) {
    // Look for end
    let maybeEnd = '';
    for (let char = indexStart + start.length; char < text.length; char += 1) {
      maybeEnd = `${text.charAt(char)}${text.charAt(char + 1)}${text.charAt(char + 2)}`;
      if (maybeEnd === end) {
        indexEnd = char + 3;
        break;
      }
    }
    // Handles unclosed shortcodes
    if (indexEnd === -1) {
      return text;
    }

    const shortcodeContents = text.slice(indexStart + start.length, indexEnd - end.length);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [quote, _, cite] = shortcodeContents.split(divider);
    const textBefore = text.slice(0, indexStart) || '';
    // Recursively process remaining text
    const remainingText = text.slice(indexEnd) || '';
    const textAfter =
      remainingText === '' ? remainingText : convertShortcodeBlockQuote(remainingText);

    return `${textBefore}<blockquote${cite ? ` cite="${cite}"` : ''}>${quote}</blockquote>${textAfter}`;
  }
  return text;
};
